@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {}

@mixin color($palette, $tone) {
    hpo-card-header {
        .header {
            &.#{$palette}-#{$tone} {
                color: var(--color-#{$palette}-#{$tone}-contrast);
                background-color: var(--color-#{$palette}-#{$tone});
            }
        }
    }
}