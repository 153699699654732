@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map.get($color-config, primary);
    $accent: map.get($color-config, accent);
    $warn: map.get($color-config, warn);

    hpo-button {
        /* Button loader colors */
        mat-spinner {
            /* Default buttons */
            &.default {
                /* Default */
                circle {
                    stroke: black !important;
                }

                &.primary circle {
                    stroke: mat.m2-get-color-from-palette($primary, default) !important;
                }

                &.accent circle {
                    stroke: mat.m2-get-color-from-palette($accent, default) !important;
                }

                &.warn circle {
                    stroke: mat.m2-get-color-from-palette($warn, default) !important;
                }
            }

            /* Raised buttons */
            &.raised {
                /* Default */
                circle {
                    stroke: black !important;
                }

                &.primary circle {
                    stroke: mat.m2-get-color-from-palette($primary, default-contrast) !important;
                }

                &.accent circle {
                    stroke: mat.m2-get-color-from-palette($accent, default-contrast) !important;
                }

                &.warn circle {
                    stroke: mat.m2-get-color-from-palette($warn, default-contrast) !important;
                }
            }
        }
    }
}
