@use '@angular/material' as mat;

$primary-palette: (
    50: var(--color-primary-50),
    // Primary Lighter
    100: var(--color-primary-lighter),
    200: var(--color-primary-200),
    300: var(--color-primary-300),
    400: var(--color-primary-400),
    // Primary Default
    500: var(--color-primary),
    600: var(--color-primary-600),
    // Primary Darker
    700: var(--color-primary-darker),
    800: var(--color-primary-800),
    900: var(--color-primary-900),
    // Accent Lighter
    A100: var(--color-primary-A100),
    // Accent Default
    A200: var(--color-primary-A200),
    // Accent Darker
    A400: var(--color-primary-A400),
    A700: var(--color-primary-A700),
    contrast: (50: var(--color-primary-50-contrast),
        100: var(--color-primary-lighter-contrast),
        200: var(--color-primary-200-contrast),
        300: var(--color-primary-300-contrast),
        400: var(--color-primary-400-contrast),
        500: var(--color-primary-contrast),
        600: var(--color-primary-600-contrast),
        700: var(--color-primary-darker-contrast),
        800: var(--color-primary-800-contrast),
        900: var(--color-primary-900-contrast),
        A100: var(--color-primary-A100-contrast),
        A200: var(--color-primary-A200-contrast),
        A400: var(--color-primary-A400-contrast),
        A700: var(--color-primary-A700-contrast))
);

$accent-palette: (
    50: var(--color-accent-50),
    // Primary Lighter
    100: var(--color-accent-100),
    200: var(--color-accent-200),
    300: var(--color-accent-300),
    400: var(--color-accent-400),
    // Primary Default
    500: var(--color-accent-500),
    600: var(--color-accent-600),
    // Primary Darker
    700: var(--color-accent-700),
    800: var(--color-accent-800),
    900: var(--color-accent-900),
    // Accent Lighter
    A100: var(--color-accent-lighter),
    // Accent Default
    A200: var(--color-accent),
    // Accent Darker
    A400: var(--color-accent-darker),
    A700: var(--color-accent-A700),
    contrast: (50: var(--color-accent-50-contrast),
        100: var(--color-accent-100-contrast),
        200: var(--color-accent-200-contrast),
        300: var(--color-accent-300-contrast),
        400: var(--color-accent-400-contrast),
        500: var(--color-accent-500-contrast),
        600: var(--color-accent-600-contrast),
        700: var(--color-accent-700-contrast),
        800: var(--color-accent-800-contrast),
        900: var(--color-accent-900-contrast),
        A100: var(--color-accent-lighter-contrast),
        A200: var(--color-accent-contrast),
        A400: var(--color-accent-darker-contrast),
        A700: var(--color-accent-A700-contrast))
);

$warn-palette: (
    50: var(--color-warn-50),
    // Primary Lighter
    100: var(--color-warn-lighter),
    200: var(--color-warn-200),
    300: var(--color-warn-300),
    400: var(--color-warn-400),
    // Primary Default
    500: var(--color-warn),
    600: var(--color-warn-600),
    // Primary Darker
    700: var(--color-warn-darker),
    800: var(--color-warn-800),
    900: var(--color-warn-900),
    // Accent Lighter
    A100: var(--color-warn-A100),
    // Accent Default
    A200: var(--color-warn-A200),
    // Accent Darker
    A400: var(--color-warn-A400),
    A700: var(--color-warn-A700),
    contrast: (50: var(--color-warn-50-contrast),
        100: var(--color-warn-lighter-contrast),
        200: var(--color-warn-200-contrast),
        300: var(--color-warn-300-contrast),
        400: var(--color-warn-400-contrast),
        500: var(--color-warn-contrast),
        600: var(--color-warn-600-contrast),
        700: var(--color-warn-darker-contrast),
        800: var(--color-warn-800-contrast),
        900: var(--color-warn-900-contrast),
        A100: var(--color-warn-A100-contrast),
        A200: var(--color-warn-A200-contrast),
        A400: var(--color-warn-A400-contrast),
        A700: var(--color-warn-A700-contrast))
);

$hpo-old-blue-palette: (
    50: #54b3db,
    100: #54b3db,
    // Primary Lighter
    200: #54b3db,
    300: #54b3db,
    400: #54b3db,
    500: #0083a9,
    // Primary Default
    600: #0083a9,
    700: #00567a,
    // Primary Darker
    800: #00567a,
    900: #00567a,
    A100: #54b3db,
    // Accent Lighter
    A200: #0083a9,
    // Accent Default
    A400: #00567a,
    // Accent Darker
    A700: #00567a,
    contrast: (50: var(--color-text-dark),
        100: var(--color-text-dark),
        200: var(--color-text-dark),
        300: var(--color-text-dark),
        400: var(--color-text-dark),
        500: var(--color-text-light),
        600: var(--color-text-light),
        700: var(--color-text-light),
        800: var(--color-text-light),
        900: var(--color-text-light),
        A100: var(--color-text-dark),
        A200: var(--color-text-light),
        A400: var(--color-text-light),
        A700: var(--color-text-light))
);

$hpo-surface-palette: (
    50: #FFFFFF,
    100: #FAFAFA,
    contrast: (50: var(--color-text-dark),
        100: var(--color-text-dark))
);

$hpo-highlight-palette: (
    50: #FAFAFA,
    contrast: (50: var(--color-text-dark))
);

/*
Colors are from 'Gray 50' palette
see: https://material.io/design/color/the-color-system.html#tools-for-picking-colors
*/
$hpo-divider-palette: (
    50: #FAFAFA,
    100: #F5F5F5,
    200: #EEEEEE,
    300: #E0E0E0,
    400: #BDBDBD,
    500: #9E9E9E,
    600: #757575,
    700: #616161,
    800: #424242,
    900: #212121,
    contrast: (50: var(--color-text-dark),
        100: var(--color-text-dark),
        200: var(--color-text-dark),
        300: var(--color-text-dark),
        400: var(--color-text-dark),
        500: var(--color-text-dark),
        600: var(--color-text-light),
        700: var(--color-text-light),
        800: var(--color-text-light),
        900: var(--color-text-light))
);

$_primary: mat.m2-define-palette($primary-palette);
$_accent: mat.m2-define-palette($accent-palette, A200, A100, A400);
$_warn: mat.m2-define-palette($warn-palette);

$_palette-config: (
    primary: $_primary,
    accent: $_accent,
    warn: $_warn
);

@function create-light-theme($typography-config) {
    $theme-config: (
        color: $_palette-config,
        typography: $typography-config
    );

@return mat.m2-define-light-theme($theme-config);
}

@function create-dark-theme($typography-config) {
    $theme-config: (
        color: $_palette-config,
        typography: $typography-config
    );

@return mat.m2-define-dark-theme($theme-config);
}