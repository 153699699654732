@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
    $typography-config: mat.m2-get-typography-config($theme);
    
    $color-config: mat.m2-get-color-config($theme);
    $primary: map.get($color-config, primary);
    $accent: map.get($color-config, accent);
    $warn: map.get($color-config, warn);

    .hpo-sb-items-menu .mat-mdc-menu-item {
        font-weight: mat.m2-font-weight($typography-config, button);

        &.mat-primary:not([disabled]) {
            color: mat.m2-get-color-from-palette($primary, default);
        }

        &.mat-accent:not([disabled]) {
            color: mat.m2-get-color-from-palette($accent, default);
        }

        &.mat-warn:not([disabled]) {
            color: mat.m2-get-color-from-palette($warn, default);
        }

        /* Inherit icon color from menu button (overrides mat-menu coloring) */
        .mat-icon-no-color {
            color: inherit;
        }
    }
}
