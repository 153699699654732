@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map.get($color-config, primary);
    $accent: map.get($color-config, accent);
    $warn: map.get($color-config, warn);

    hpo-notification {
        .message-container {
            &.primary {
                background-color: mat.m2-get-color-from-palette($primary, darker);
                color: mat.m2-get-color-from-palette($primary, darker-contrast);
            }

            &.accent {
                background-color: mat.m2-get-color-from-palette($accent, darker);
                color: mat.m2-get-color-from-palette($accent, darker-contrast);
            }

            &.warn {
                background-color: mat.m2-get-color-from-palette($warn, darker);
                color: mat.m2-get-color-from-palette($warn, darker-contrast);
            }
        }
    }
}
