@import '@headpower/layout/assets/layout';
@import '@headpower/layout/assets/hpo-component-themes';
@import '@headpower/layout/assets/app-component-themes';

// Import Kendo component styles
@import '@progress/kendo-theme-default/scss/signature/index';

// Kendo global variables override
body {
    --kendo-font-size: 1.4rem;
}

.notification-container .content-container img,
.notification-page hpo-card-content img,
.importantNewsContent img {
    max-width: 100%;
    height: auto;
}

.notification-container .content-container iframe,
video,
.notification-page hpo-card-content iframe,
video,
.importantNewsContent iframe,
video {
    width: 100%;
}

.copy-link {
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
}

.hpo-theme .custom-dialog-container .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    padding: 10px;
}

.material-symbols-filled {
    font-variation-settings: 'FILL' 1;
}