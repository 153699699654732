@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map.get($color-config, primary);

    hpo-full-screen-dialog {
        .toolbar {
            color: mat.m2-get-color-from-palette($primary, default-contrast);
            background-color: mat.m2-get-color-from-palette($primary, default);
        }

        .close-button {
            color: mat.m2-get-color-from-palette($primary, default-contrast);
        }

        .toolbar-action {
            .mdc-button {
                color: mat.m2-get-color-from-palette($primary, default-contrast);
            }
        }
    }
}