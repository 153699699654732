@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map.get($color-config, primary);

    hpo-nav-item {
        .nav-item:active,
        .nav-item:hover,
        .nav-item-active {
            color: mat.m2-get-color-from-palette($primary, default) !important;
        }
    }
}
