@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map.get($color-config, primary);

    app-home {
        .card-panel-title,
        .card-panel-title-mobile {
            color: mat.m2-get-color-from-palette($primary, default);
        }
    }
}
